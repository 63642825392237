<template>
  <v-skeleton-loader v-if="!widgetData" type="image"></v-skeleton-loader>
  <v-card v-else elevation="2" outlined rounded>
    <v-card-title>
      <h4>{{ currentDayOfWeek }}</h4>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-list-item-content>
            <div class="current-date">{{ currentDay }}</div>
          </v-list-item-content>
        </v-col>

        <v-col cols="8">
          <v-list-item v-for="event in filterEventsByDay" :key="event.id" dense>
            <div :style="{ backgroundColor: getEventColor(event) }" class="event-bar"></div>
            <v-list-item-content>
              <v-list-item-title> {{ event.name }}</v-list-item-title>
              <v-list-item-subtitle> {{ formatTime(event.start) }} - {{ formatTime(event.end) }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts" src="./go-gadget-calendar-event-list.component.ts"></script>
<style scoped>
.current-date {
  font-size: 2.5em;
  line-height: 1.1;
  color: black;
}

.event-bar {
  position: absolute;
  left: 10px;
  width: 3px;
  height: 50%;
  background-color: grey;
  top: 50%; /* Center the bar vertically */
  transform: translateY(-50%); /* Adjust for center alignment */
  z-index: 0; /* Send to back */
}

.v-list-item {
  position: relative;
  padding-left: 20px;
}
</style>
