<template>
  <v-menu offset-y min-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" :disabled="!authenticated">
        <v-avatar v-if="!authenticated">
          <v-icon>mdi-account</v-icon>
        </v-avatar>
        <v-avatar v-else color="white" size="32">
          <span class="black--text font-weight-medium">{{ initials }}</span>
        </v-avatar>
      </v-btn>
    </template>

    <div v-if="authenticated">
      <v-card>
        <v-list style="background-color: #e8e8e8">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large>mdi-robot-industrial-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-col class="pa-0" cols="8">
                <h3 class="text-center" style="font-weight: normal">
                  {{ user.name }}
                </h3>
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="pa-0" cols="4">
                  <h1 class="text-center">
                    {{ getDevices() }}
                  </h1>
                </v-col>
                <v-col class="pa-0" cols="6">
                  <v-list-item-title>{{ $t('raptor.operatorLanding.workCenterDevicesDialog.title') }} </v-list-item-title>
                  <v-list-item-subtitle>{{ $t('raptor.operatorLanding.workCenterDevicesDialog.subtitle') }} </v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-icon>mdi-devices</v-icon>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="pa-0" cols="4">
                  <h1 class="text-center">
                    {{ getOperators() }}
                  </h1>
                </v-col>
                <v-col class="pa-0" cols="6">
                  <v-list-item-title>{{ $t('raptor.workCenterOperator.frequentOperators') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('raptor.operatorLanding.workCenterDevicesDialog.subtitle') }} </v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-icon>mdi-account-hard-hat</v-icon>
          </v-list-item>
          <template v-if="newOperators.length">
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col class="pa-0" cols="4">
                    <h1 class="text-center">
                      {{ getNewOperators() }}
                    </h1>
                  </v-col>
                  <v-col class="pa-0" cols="6">
                    <v-list-item-title>{{ $t('raptor.workCenterOperator.noFrequentOperators') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('raptor.operatorLanding.workCenterDevicesDialog.subtitle') }} </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-list-item>
          </template>
        </v-list>

        <v-divider></v-divider>

        <v-spacer></v-spacer>
        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-content style="margin-left: 22px">
              <v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" style="font-weight: bold" text>
                    {{ $t('logout') }}
                  </v-btn>
                </v-card-actions>
              </v-row>
            </v-list-item-content>
            <v-icon>mdi-logout</v-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <div v-else>
      <p>dkjsngfbngfg</p>
    </div>
  </v-menu>
</template>
<script lang="ts" src="./go-account.component.ts"></script>

<style scoped>
.icon-title {
  margin-left: 12px;
}
</style>
