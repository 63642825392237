<template>
  <div>
    <v-skeleton-loader v-if="!widgetData" type="image"></v-skeleton-loader>
    <v-card v-else>
      <v-date-picker
        no-title
        :events="filteredEvents"
        :event-color="eventColorByCalendarFilter"
        @click:date="onClickDate"
        show-adjacent-months
        :show-current="model"
        scrollable
        full-width
        elevation="2"
      ></v-date-picker>
    </v-card>
  </div>
</template>
<script lang="ts" src="./go-gadget-calendar.component.ts"></script>
<style>
.v-application .accent--text {
  color: #8e8e8e !important;
}
</style>
